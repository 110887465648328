<!-- eslint-disable max-len -->
<template>
   <Alert v-if="dailyTriesText" kind="warning">{{ dailyTriesText }}</Alert>
</template>

<script>
import { Alert } from '@/components';

export default {
  name: 'daily-tries-counter-alert',
  components: {
    Alert,
  },
  props: {
    dailyTriesCounter: {
      type: Number,
    },
  },
  computed: {
    dailyTriesText() {
      if (this.dailyTriesCounter > 1) {
        return `Esse CPF já possui ${this.dailyTriesCounter} link de pagamento de 30 possíveis no dia.`;
      }

      if (this.dailyTriesCounter === 30) {
        return 'Este é o último link de pagamento disponível para esse CPF hoje.';
      }

      return '';
    },
  },
};
</script>

<style scoped>
.bg-amber {
  background-color: #FEF3C7;
}

.bg-amber h3 {
  color: #B45309;
}
</style>
