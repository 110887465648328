<template>
  <div class="flex flex-col min-h-full">
    <div class="flex items-center justify-between gap-4 bg-white border-b py-1 lg:py-2 px-6">
      <h3 class="text-gray-500 text-lg">Novo link de pagamento</h3>
      <Button aria-label="Close" kind="plain" class="-mr-3" @click="showModal">
        <XIcon size="24" />
        <span class="sr-only"> Fechar </span>
      </Button>
    </div>
    <DailyTriesCounterAlert :dailyTriesCounter="dailyTriesCounter" />
    <div class="flex-grow bg-white lg:bg-gray-100 w-full">
      <div class="max-w-lg mx-auto bg-white py-6 lg:border lg:rounded-xl lg:my-6">
        <header class="mb-6 px-6">
          <h1 class="font-bold text-xl leading-9">Dados do pagamento</h1>
        </header>
        <div v-if="hasName" class="grid lg:grid-cols-2 lg:items-center gap-2 px-6 py-3
          bg-green-50 text-green-700 text-sm">
          <div class="flex lg:flex-col gap-3 lg:gap-0">
            <div class="lg:flex w-full">
              <div class="mr-1">Cliente: </div>
              <div class="font-semibold capitalize">
                {{
        getPreApprovedCredit.checkoutData
          ? getPreApprovedCredit.checkoutData.name.toLowerCase()
          : getPreApprovedCredit.name.toLowerCase()
      }}
              </div>
            </div>
            <div class="lg:flex w-full">
              <div class="mr-1">Limite com entrada: </div>
              <div class="font-semibold">{{
          parseInt(getPreApprovedCredit.maxValue).toLocaleString(
            "pt-BR",
            {
              style: "currency",
              currency: "BRL",
            }
          )
        }}</div>
            </div>
          </div>
          <div class="w-full flex flex-col
            text-green-700 border border-green-600 rounded-lg
            bg-white px-3 py-2">
            <div>Limite sem entrada</div>
            <div class="font-semibold text-base">{{
          `até ${parseInt(maxValueWithoutDownPayment).toLocaleString(
            "pt-BR",
            {
              style: "currency",
              currency: "BRL",
            }
          )}`
        }}</div>
          </div>
        </div>

        <div class="space-y-5 p-6">
          <div v-if="!preApprovedCredit">
            <div class="flex justify-between">
              <label class="form-label">Nome do cliente</label>
              <span class="text-gray-500 text-sm">
                {{
        newPaymentLink.customerName
          ? newPaymentLink.customerName.length
          : 0
      }}/50 caracteres
              </span>
            </div>
            <Input type="text" v-model="newPaymentLink.customerName"
              @input="handleFormInput('customerName', ...arguments)"
              :errorMessage="getMessage('customerName')"
              :maxLength="formInputs.customerName.extraValidateFunctionParams.max
        " />
          </div>

          <div>
            <div class="flex justify-between">
              <label class="form-label">Descrição do pedido</label>
              <span class="text-gray-500 text-sm">
                {{
        newPaymentLink.description
          ? newPaymentLink.description.length
          : 0
      }}/160 caracteres
              </span>
            </div>
            <Input type="textarea" v-model="newPaymentLink.description"
              @input="handleFormInput('description', ...arguments)"
              :errorMessage="getMessage('description')"
              :maxLength="formInputs.description.extraValidateFunctionParams.max
        " />
          </div>

          <div class="lg:w-1/2">
            <label class="form-label">Valor</label>
            <Input type="cash" v-model="maskedPrice" @input="handleFormInput('value', ...arguments)"
              :errorMessage="getMessage('value')" />
          </div>

          <div v-if="!preApprovedCredit">
            <label class="form-label">Celular</label>
            <Input type="text" v-model="newPaymentLink.customerPhone"
              @input="handleFormInput('customerPhone', ...arguments)"
              :errorMessage="getMessage('customerPhone')"
              v-mask="'(##) #####-####'" />
          </div>

          <div v-if="!preApprovedCredit">
            <label class="form-label">Email</label>
            <span class="text-sm text-gray-500">(opcional)</span>
            <Input type="text" v-model="newPaymentLink.customerEmail"
              @input="handleFormInput('customerEmail', ...arguments)"
              :errorMessage="getMessage('customerEmail')" />
          </div>
        </div>
      </div>
    </div>
    <nav class="py-4 px-6 border-t bg-white lg:sticky lg:bottom-0 lg:z-10">
      <div class="flex gap-4">
        <div class="flex flex-grow gap-4 justify-end">
          <Button kind="primary" link @click="handleNextStep">
            <span>Avançar</span>
            <ChevronRightIcon size="20" />
          </Button>
        </div>
      </div>
    </nav>
    <CancelCreatePaymentLinkModal v-if="!preApprovedCredit"
    :showModal="openModal" @onCloseModal="showModal" />
    <PaymentLinkSavedModal v-else :showModal="openModal" @onCloseModal="showModal" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { mask } from 'vue-the-mask';
import { ChevronRightIcon } from '@vue-hero-icons/solid';
import { XIcon } from '@vue-hero-icons/outline';
import { Input, Button } from '../../../components';
import utilMixin from '../../../mixins/util';
import stringMixin from '../../../mixins/string';
import PaymentLinkSavedModal from '../components/PaymentLinkSavedModal.vue';
import CancelCreatePaymentLinkModal from '../components/CancelCreatePaymentLinkModal.vue';
import DailyTriesCounterAlert from '../components/DailyTriesCounterAlert.vue';

export default {
  name: 'create-payment-link',
  metaInfo: {
    title: 'Novo link de pagamento',
  },
  components: {
    ChevronRightIcon,
    XIcon,
    PaymentLinkSavedModal,
    DailyTriesCounterAlert,
    CancelCreatePaymentLinkModal,
    Input,
    Button,
  },
  mixins: [utilMixin, stringMixin],
  directives: {
    mask,
  },
  data: () => ({
    openModal: false,
    maskedPrice: 0,
    formInputs: {
      customerName: {
        isValid: false,
        validateFunction: 'isBetweenMinAndMaxLength',
        extraValidateFunctionParams: {
          min: 1,
          max: 50,
        },
        errorMessage: 'Preencha o nome do cliente',
        currentMessage: '',
      },
      description: {
        isValid: false,
        validateFunction: 'isBetweenMinAndMaxLength',
        extraValidateFunctionParams: {
          min: 1,
          max: 160,
        },
        errorMessage: 'Preencha a descrição do pedido',
        currentMessage: '',
      },
      value: {
        validateFunction: 'isValidValue',
        isValid: false,
        errorMessage: 'Preencha um valor',
        currentMessage: '',
      },
      customerPhone: {
        isValid: false,
        validateFunction: 'isValidPhone',
        errorMessage: 'Insira um celular válido',
        currentMessage: '',
      },
      customerEmail: {
        isValid: true,
        validateFunction: 'hasValueAndIsValidEmail',
        errorMessage: 'Insira um email válido',
        currentMessage: '',
      },
    },
  }),
  computed: {
    ...mapState('paymentLink', ['newPaymentLink', 'preApprovedCredit']),
    ...mapGetters('paymentLink', ['dailyTries', 'getPreApprovedCredit']),
    dailyTriesCounter() {
      return this.dailyTries;
    },
    hasName() {
      if (this.preApprovedCredit?.name || this.preApprovedCredit?.checkoutData?.name) {
        return this.preApprovedCredit
          && (this.preApprovedCredit?.name || this.preApprovedCredit?.checkoutData.name);
      }
      return '';
    },
    maxValueWithoutDownPayment() {
      if (!this.getPreApprovedCredit) return null;

      let value = 0;
      if (this.getPreApprovedCredit.pricings) {
        const index = Object.values(
          this.getPreApprovedCredit.pricings,
        ).findLastIndex((item) => item[0].downPaymentPercentage === 0);

        if (index !== -1) {
          const lastRange = Object.keys(this.getPreApprovedCredit.pricings)[
            index
          ];
          value = Number(lastRange.match(new RegExp('(?<=[-]).*')));
        }
      }
      return value;
    },
  },
  methods: {
    ...mapActions('paymentLink', [
      'setNewPaymentLinkData',
      'createPaymentLink',
      'verifyCredit',
      'getSanitizedPaymentLinkUser',
    ]),
    ...mapActions('loading', ['setIsLoading']),
    ...mapActions('toast', ['showToast']),
    showModal() {
      this.openModal = !this.openModal;
    },
    isValidForm() {
      let isValid = true;
      const keys = Object.keys(this.formInputs);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (!this.formInputs[key].isValid) {
          isValid = false;
          break;
        }
      }
      return isValid;
    },
    async handleNextStep() {
      if (this.preApprovedCredit) {
        await this.applyForPaymentLink();
        return;
      }

      await this.createNewPaymentLink();
    },
    async createNewPaymentLink() {
      if (!this.isValidForm()) {
        this.handleErrorMessages();
        return;
      }

      try {
        this.setIsLoading({ isLoading: true });
        const { inputError } = await this.createPaymentLink();
        if (inputError && inputError === 'email') {
          this.formInputs.customerEmail.isValid = false;
          this.handleErrorMessages();
        }

        this.$router.replace({ name: 'review-payment-link' });
      } catch (error) {
        if (error.response && error.response.data) {
          const errorMessage = error.response.data;
          if (errorMessage === 'jwt malformed') {
            this.showToast({
              toastMessage: 'Erro de autenticação: Realize login novamente.',
              toastType: 'danger',
              hideToastDelay: 5000,
            });
            return;
          }

          if (errorMessage.includes('ValidationError')) {
            const validationError = errorMessage.split(': ')[1];
            this.showToast({
              toastMessage: `Erro de validação: ${validationError}. Contate o suporte com a mensagem`,
              toastType: 'danger',
              hideToastDelay: 5000,
            });
          } else {
            this.showToast({
              toastMessage: `Erro inesperado: ${errorMessage}. Contate o suporte com a mensagem`,
              toastType: 'danger',
              hideToastDelay: 5000,
            });
          }
        }
      }

      this.setIsLoading({ isLoading: false });
    },
    async applyForPaymentLink() {
      if (!this.isValidForm()) {
        this.handleErrorMessages();
        return;
      }

      try {
        this.setIsLoading({ isLoading: true });
        await this.verifyCredit();

        if (
          this.newPaymentLink.creditApplyStatus === 'DENIED'
          && this.dailyTriesCounter > 30
        ) {
          this.$router.replace({
            name: 'payment-link-number-of-link-exceeded',
          });
        } else if (this.newPaymentLink.creditApplyStatus === 'DENIED') {
          this.$router.replace({ name: 'denied-payment-link' });
        } else {
          this.$router.replace({ name: 'review-payment-link' });
        }
      } catch (error) {
        if (error.response && error.response.data) {
          const errorMessage = error.response.data;
          if (errorMessage.includes('ValidationError')) {
            const validationError = errorMessage.split(': ')[1];
            this.showToast({
              toastMessage: `Erro de validação: ${validationError}. Contate o suporte com a mensagem`,
              toastType: 'danger',
              hideToastDelay: 5000,
            });
          } else {
            this.showToast({
              toastMessage: `Erro inesperado: ${errorMessage}. Contate o suporte com a mensagem`,
              toastType: 'danger',
              hideToastDelay: 5000,
            });
          }
        }
      }

      this.setIsLoading({ isLoading: false });
    },

    // Form Fields Validators
    isBetweenMinAndMaxLength(value, { min, max }) {
      return value && value.length >= min && value.length <= max;
    },
    hasValueAndIsValidEmail(value) {
      if (!value) return true;
      return this.isValidEmail(value);
    },
    isValidValue(value) {
      if (!value || value === 0) return false;
      const maxValue = (this.preApprovedCredit && this.preApprovedCredit.maxValue) || 20000;
      return value && value >= 100 && value <= maxValue;
    },

    // Form Handlers
    handleFormInput(field, value) {
      this.setNewPaymentLinkData({ field, value });
      const { validateFunction, extraValidateFunctionParams } = this.formInputs[field];
      this.formInputs[field].isValid = this[validateFunction](
        value,
        extraValidateFunctionParams,
      );
      if (this.formInputs[field].isValid) {
        if (this.formInputs[field].currentMessage !== '') {
          this.formInputs[field].currentMessage = '';
        }
      }
    },
    handleErrorMessages() {
      Object.keys(this.formInputs).forEach((key) => {
        if (!this.formInputs[key].isValid) {
          this.formInputs[key].currentMessage = this.formInputs[key].errorMessage;
        }
      });
    },
    getMessage(field) {
      return this.formInputs[field].currentMessage;
    },
  },
  async mounted() {
    if (this.preApprovedCredit) {
      const paymentLinkUser = await this.getSanitizedPaymentLinkUser();

      this.formInputs.customerName.isValid = true;

      this.formInputs.customerPhone.isValid = true;

      this.setNewPaymentLinkData({
        field: 'customerPhone',
        value: paymentLinkUser.phone,
      });

      this.formInputs.customerEmail.isValid = true;
      this.setNewPaymentLinkData({
        field: 'customerEmail',
        value: paymentLinkUser.email,
      });
      this.setNewPaymentLinkData({
        field: 'customerZipCode',
        value: paymentLinkUser.zipCode,
      });
    }
  },
};
</script>
<style scoped>
.bg-amber {
  background-color: #fef3c7;
}

.bg-amber h3 {
  color: #b45309;
}
</style>
